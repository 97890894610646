<template>
  <div class="infoCard">
    <div class="card-info-box">
      <div class="searchInfo">
        <a-input
          v-model="queryParams.keywords"
          placeholder="请输入申请方企业名称、个人名称或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="handleReload" style="width: 81px">重置</a-button>
        </div>
      </div>
      <a-form-model
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        style="margin-top: 8px"
        :colon="false"
      >
        <a-form-model-item label="发票状态">
          <checkboxSearch :options="invoiceData" v-model="queryParams.invoiceStatusList" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="开票时间">

          <radioGroup
            v-model="OpenTimeScoped"
            :options="otherOptions"
            :needLoad="false"
            :needAll="false"
            @change="handleChangeRadio($event, 'CreateTimeScoped')"
          >
            <a-radio :value="-1">
              <a-range-picker
                v-model="OpenTimeScopedRange"
                value-format="YYYY-MM-DD HH:mm:ss"
                separator="⇀"
                showTime
                @change="handleChangeDate($event, 'CreateTimeScoped')"
                style="width: 310px; height: 26px"
              >
                <span class="pickerDict">
                  <span v-if="OpenTimeScopedRange.length"
                  >{{ OpenTimeScopedRange[0] }} ~ {{ OpenTimeScopedRange[1] }}</span
                  >
                  <span class="placeholder" v-else>请选择</span>
                  <a-icon type="caret-down" style="margin-left: 8px; color: #999"></a-icon>
                </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
        <a-form-model-item label="申请时间">

          <radioGroup
            v-model="CreateTimeScoped"
            :options="otherOptions"
            :needLoad="false"
            :needAll="false"
            @change="handleChangeRadio($event, 'CreateTimeScoped')"
          >
            <a-radio :value="-1">
              <a-range-picker
                v-model="CreateTimeScopedRange"
                value-format="YYYY-MM-DD HH:mm:ss"
                separator="⇀"
                showTime
                @change="handleChangeDate($event, 'CreateTimeScoped')"
                style="width: 310px; height: 26px"
              >
                <span class="pickerDict">
                  <span v-if="CreateTimeScopedRange.length"
                  >{{ CreateTimeScopedRange[0] }} ~ {{ CreateTimeScopedRange[1] }}</span
                  >
                  <span class="placeholder" v-else>请选择</span>
                  <a-icon type="caret-down" style="margin-left: 8px; color: #999"></a-icon>
                </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
      </a-form-model>

    </div>
    <div class="card-info-box">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
      >
        <template slot="name" slot-scope="text">
          <a>{{ text }}</a>
        </template>
        <template slot="invoiceStatus" slot-scope="text,record">
           <span class="table-status-btn" :class="statusDictStyle[text + '']">
            {{ (text && vnioiveStatusList[text + '']) || '' }}
          </span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="handleSwitchTab(record)">
            <a-icon type="eye" />
            详情</a>
          <a-divider type="vertical" />
          <a @click="toRedInfo(record)" v-if="record.invoiceStatus==4">
            <a-icon type="scissor" />
            红冲</a>
          <a-divider type="vertical" />
          <a @click="handleDownload(record)">
            <a-icon type="download" />
            下载</a>
        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <BlushDrawer ref="redInfoRef"></BlushDrawer>
    <InvoiceDetails ref="invoicedetail"></InvoiceDetails>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { downloadImage, getDateNewRange, getDateRange } from '@/util/utils'
import BlushDrawer from '@/views/invoice/supplier/modules/blushDrawer.vue'
import InvoiceDetails from '@/views/invoice/supplier/invoiceDetails.vue'

export default {
  name: 'ContractManagement',
  mixins: [ListMixin],
  components: {
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup, BlushDrawer,
    InvoiceDetails

  },

  data() {
    return {
      columns: [
        {
          title: '开票时间',
          dataIndex: 'invoiceTime',
          key: 'invoiceTime',
          width: 180
        },
        {
          title: '申请时间',
          dataIndex: 'applyTime',
          key: 'applyTime',
          width: 180
        },
        {
          title: '开票企业',
          dataIndex: 'supplierBusinessName',
          // align: 'center',
          key: 'supplierBusinessName',
          scopedSlots: { customRender: 'compayName' }
        }, {
          title: '发票抬头',
          dataIndex: 'invoiceHead',
          // align: 'center',
          key: 'invoiceHead',
          width: 180,
          scopedSlots: { customRender: 'invoiceName' }
        },
        {
          title: '发票总额 ',
          dataIndex: 'amount',
          key: 'amount',
          width: 100
        },
        {
          title: ' 发票状态  ',
          dataIndex: 'invoiceStatus',
          key: 'invoiceStatus',
          width: 90,
          scopedSlots: { customRender: 'invoiceStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 215,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      CreateTimeScoped: [],
      OpenTimeScoped: [],
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined
      },
      rangeDate: [],
      CreateTimeScopedRange: [],
      OpenTimeScopedRange: [],
      invoiceData: [
        {
          dictKey: '2',
          dictValue: '已开票'
        },
        {
          dictKey: '4',
          dictValue: '待退票'
        },
        {
          dictKey: '5',
          dictValue: '已红冲'
        }
      ],
      otherOptions: [
        {
          dictKey: '2',
          dictValue: '本月'
        },
        {
          dictKey: '3',
          dictValue: '三个月'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        }
      ],
      mainOptions: [],
      activeInfo: {},
      requestType: 'post',
      url: {
        list: '/v1/invoice/invoiceList'
      },
      createTimeScoped: '',
      openTimeScoped: '',
      createTimeRange: [],
      openTimeRange: [],
      orderNo: null,
      disableMixinCreated: true,
      vnioiveStatusList: {
        1: '开票中',
        2: '已开票',
        3: '已作废',
        4: '退票中',
        5: '已红冲'
      }, statusDictStyle: {
        1: 'status_2',
        2: 'status_4',
        3: 'status_10',
        4: 'status_2',
        5: 'status_8'
      }
    }
  },
  created() {
    this.handleLoadData(1)
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${ key }Range`] = []
      }
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleInit() {
      getAction('/quote/dictionary').then((res) => {
        const { success, data } = res
        success && (this.mainOptions = data)
      })
    },
    handleJump(url) {
      window.open(url)
    },
    handleUploadContract(item) {
      this.$refs.newUploader.handleOpen(item)
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      }
      const {
        status,
        keywords,
        startDate,
        endDate,
        cooperationStatus,
        invoiceStatusList
      } = this.queryParams
      let statusList = [], params = { keywords, startDate, endDate }
      if (invoiceStatusList) {
        params.invoiceStatusList = invoiceStatusList.split(',')
      } else {
        params.invoiceStatusList = [2, 3, 4, 5]
      }
      if (status) {
        statusList = status.split(',')
      }
      if (cooperationStatus) {
        params = { ...params, cooperationStatus: cooperationStatus.split(',') }
      }
      // 申请时间
      if (this.CreateTimeScoped != '' && this.CreateTimeScoped != -1) {
        if (this.CreateTimeScoped == '6') {
          let lastYear = new Date().getFullYear() - 1
          params = {
            ...params,
            applyDateStart: `${ lastYear }-01-01 00:00:00`,
            applyDateEnd: `${ lastYear }-12-31 23:59:59`
          }
        } else {
          const [applyDateStart, applyDateEnd] = getDateRange(this.CreateTimeScoped)
          params = { ...params, applyDateStart, applyDateEnd }
        }
      } else if (this.CreateTimeScoped != -1 && this.CreateTimeScopedRange.length) {
        const [applyDateStart, applyDateEnd] = this.CreateTimeScopedRange
        params = { ...params, applyDateStart, applyDateEnd }
      }
      // 申请时间
      if (this.OpenTimeScoped != '' && this.OpenTimeScoped != -1) {
        if (this.OpenTimeScoped == '6') {
          let lastYear = new Date().getFullYear() - 1
          params = {
            ...params,
            invoiceDateStart: `${ lastYear }-01-01 00:00:00`,
            invoiceDateEnd: `${ lastYear }-12-31 23:59:59`
          }
        } else {
          const [invoiceDateStart, invoiceDateEnd] = getDateRange(this.CreateTimeScoped)
          params = { ...params, invoiceDateStart, invoiceDateEnd }
        }
      } else if (this.OpenTimeScoped != -1 && this.OpenTimeScopedRange.length) {
        const [invoiceDateStart, invoiceDateEnd] = this.OpenTimeScopedRange
        params = { ...params, invoiceDateStart, invoiceDateEnd }
      }

      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        statusList,
        pagesSize: pageSize,
        pageNum,
        ...params
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.dataSource = list
            this.pageNation['total'] = total
            this.$emit('count', total)
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
      this.queryParams = {}
      this.createTimeRange = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    toAddAddress() {
      this.$refs.addressModal.handleOpen({})
    },
    toEditAddress(item) {
      this.$refs.addressModal.handleOpen(item)
    },
    toCopyAddress(item) {
      delete item.id
      this.$refs.addressModal.handleOpen(item)
    },
    onDelete() {

    }
    , handleReload() {
      this.queryParams = {
        status: [],
        orderNo: null
      }
      this.CreateTimeScopedRange = []
      this.orderStatus = ''
      this.CreateTimeScoped = '3'
      this.handleLoadData(1)
    },
    toRedInfo(record) {
      console.log('aaaaaaaaaaaaaaa')
      this.$refs.redInfoRef.handleOpen(record)
    },
    handleDownload(record) {
      if (!record.invoiceFile) return this.$message.error('暂无发票文件')
      record.invoiceFile.split(',').map((item) => {
        downloadImage(item)
      })
    }, handleSwitchTab(record) {
      this.$refs.invoicedetail.handleOpen(record)

      // const { id } = record
      // console.log(this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1)
      // this.$router.push(
      //   `/invoice/supplierdetail?id=${ id }`
      // )
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;

    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';

::v-deep .ant-form-item-label {
  width: 67px !important;
}

.fileUploader {
  display: none;
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 38px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.inline-name {
  width: 100%;
  display: flex;
  align-items: center;

  span.label {
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

span.tag {
  display: inline-block;
  width: 32px;
  height: 20px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: #05c29c;
  margin-right: 6px;

  &.danger {
    background: #ee4261;
  }
}
</style>
