<template>
  <div class="infoCard1">
    <div class="card-info-box" style="padding: 0px 24px 20px 24px">
      <div class="searchInfo">
        <a-input
          v-model="queryParams.orderNo"
          placeholder="请输入开票企业或其他关键词"
          style="width: 480px; height: 38px; line-height: 38px"
        />
        <div class="option-area">
          <a-button type="primary" @click="handleSearch" style="width: 81px">查询</a-button>
          <a-button @click="handleReload" style="width: 81px">重置</a-button>
        </div>
      </div>
      <a-form-model
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        style="margin-top: 8px"
        :colon="false"
      >
        <a-form-model-item label="发票状态">
          <checkboxSearch :options="invoiceDictData" v-model="queryParams.invoiceStatus" :needLoad="false" />
        </a-form-model-item>
        <a-form-model-item label="申请时间">

          <radioGroup
            v-model="CreateTimeScoped"
            :options="otherOptions"
            :needLoad="false"
            :needAll="false"
            @change="handleChangeRadio($event, 'CreateTimeScoped')"
          >
            <a-radio :value="-1">
              <a-range-picker
                v-model="CreateTimeScopedRange"
                value-format="YYYY-MM-DD HH:mm:ss"
                separator="⇀"
                showTime
                @change="handleChangeDate($event, 'CreateTimeScoped')"
                style="width: 310px; height: 26px"
              >
                <span class="pickerDict">
                  <span v-if="CreateTimeScopedRange.length"
                  >{{ CreateTimeScopedRange[0] }} ~ {{ CreateTimeScopedRange[1] }}</span
                  >
                  <span class="placeholder" v-else>请选择</span>
                  <a-icon type="caret-down" style="margin-left: 8px; color: #999"></a-icon>
                </span>
              </a-range-picker>
            </a-radio>
          </radioGroup>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="card-info-box">
      <a-table
        :columns="columns"
        :loading="loading"
        :data-source="dataSource"
        :pagination="false"
        style="margin-bottom: 16px"
        :scroll="{ x: 'max-content' }"
        rowKey="id"

      >
        <template slot="name" slot-scope="text">
          <a>{{ text }}</a>
        </template>
        <template slot="invoiceStatus" slot-scope="text,record">
           <span class="table-status-btn" :class="statusDictStyle[text + '']">
            {{ (text && vnioiveStatusList[text + '']) || '' }}
          </span>
        </template>

        <template slot="operation" slot-scope="text, record">
          <a @click="handleDownload(record)">
            <a-icon type="download" />
            下载</a>
          <a-divider type="vertical" />
          <a @click="handleSwitchTab(record)">
            <a-icon type="eye" />
            详情</a>
          <a-divider type="vertical" />
          <a @click="handleRefund(record)">
            <a-icon type="rollback" />
            退票</a>
          <a-divider type="vertical" />
          <a @click="handleToVoid(record)">
            <a-icon type="warning" />
            作废</a>

        </template>
      </a-table>
      <ZPagination
        :total="pageNation.total"
        :current="pageNation.pageNum"
        :pageSize="pageNation.pageSize"
        @handlePageChange="handleChangePage"
      />
    </div>
    <a-modal :visible="toVoidvisible" centered @ok="handleVoidOk" @cancel="handleCancel">
      <template #title>
        <winTitle title="发票作废确认" sub-title="" />
      </template>
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>请确认是否需要作废发票？作废发票后，可重新申请发票~</span>
      </div>
    </a-modal>
    <a-modal :visible="refundVisible" centered @ok="handleRefundOk" @cancel="handleCancel">
      <template #title>
        <winTitle title="发票退票确认" sub-title="" />
      </template>
      <div class="info-card">
        <a-form-model
          class="form-cover"
          ref="basicForm"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :colon="false"
        >
          <a-row :gutter="26" class="basicInfo-form-item" >
            <a-col :span="24">
              <a-form-model-item style="display: flex;"
                                 label="退票原因"
                                 :rules="{ required: true, message: '请选择退票原因' }"
                                 prop="reason" >
                <j-dictSelect
                  style="width: 320px; height: 38px;margin-left: 16px"
                  v-model="form.reason"
                  dictCode="1540"
                  placeholder="请选择"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="26" class="basicInfo-form-item" >
            <a-col>
              <a-form-model-item style="display: flex;"
                                 label="备注"
                                 prop="refundRemark"
                                 :rules="{ required: true, message: '请输入备注' }">
                <a-textarea style="width: 320px; margin-left: 16px" placeholder="请输入"
                            v-model="form.refundRemark"
                            allow-clear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
    <InvoiceDetails ref="invoicedetail"></InvoiceDetails>
  </div>

</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { downloadImage, getDateNewRange, getDateRange } from '@/util/utils'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import WinTitle from '@/components/winTitle/index.vue'
import  InvoiceDetails from '@/views/invoice/purchaser/invoiceDetails.vue'

export default {
  name: 'ContractManagement',
  mixins: [ListMixin],
  components: {
    WinTitle,
    JDictSelect,
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup,
    InvoiceDetails
  },

  data() {
    return {

      targetVoidId: null,
      form: {},
      toVoidvisible: false,
      refundVisible: false,
      CreateTimeScoped: [],
      columns: [
        {
          title: '申请时间',
          dataIndex: 'applyTime',
          key: 'applyTime',
          width: 180
        },
        {
          title: '开票企业',
          dataIndex: 'supplierBusinessName',
          // align: 'center',
          key: 'supplierBusinessName',
          width: 180,
          scopedSlots: { customRender: 'compayName' }
        }, {
          title: '发票抬头',
          dataIndex: 'invoiceHead',
          // align: 'center',
          key: 'invoiceHead',
          width: 180,
          scopedSlots: { customRender: 'invoiceName' }
        },
        {
          title: '发票总额 ',
          dataIndex: 'amount',
          key: 'amount',
          width: 120
        },
        {
          title: ' 发票状态  ',
          dataIndex: 'invoiceStatus',
          key: 'invoiceStatus',
          width: 100,
          scopedSlots: { customRender: 'invoiceStatus' }

        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 270,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined
      },
      rangeDate: [],
      CreateTimeScopedRange: [],
      invoiceDictData: [
        {
          dictKey: '1',
          dictValue: '开票中'
        },
        {
          dictKey: '2',
          dictValue: '已开票'
        },
        {
          dictKey: '3',
          dictValue: '已作废'
        },
        {
          dictKey: '4',
          dictValue: '退票中'
        },
        {
          dictKey: '5',
          dictValue: '已红冲'
        }
      ],
      otherOptions: [
        {
          dictKey: '2',
          dictValue: '本月'
        },
        {
          dictKey: '3',
          dictValue: '三个月'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        }
      ],
      mainOptions: [],
      activeInfo: {},
      requestType: 'post',
      url: {
        list: '/v1/invoice/invoiceList'
      },
      vnioiveStatusList: {
        1: '开票中',
        2: '已开票',
        3: '已作废',
        4: '退票中',
        5: '已红冲'
      },
      statusDictStyle: {
        1: 'status_2',
        2: 'status_4',
        3: 'status_10',
        4: 'status_2',
        5: 'status_8'
      },
      statusADictList: {
        0: '待生效',
        1: '待签署',
        2: '已签署 '
      },
      //  0:发起 1:签署中 2 签署完成
      statusADictStyle: {
        0: 'status_1',
        1: 'status_6',
        2: 'status_4'
      },
      createTimeScoped: '',
      createTimeRange: [],
      orderNo: null,
      disableMixinCreated: true,

    }
  },
  created() {
    //this.handleInit()
    // if (this.$route.query && this.$route.query.contractNo) {
    //   this.queryParams.contractNo = this.$route.query.contractNo
    //   //this.handleLoadData()
    // } else {
    //   // this.handleLoadData(1)
    // }
    this.handleLoadData(1)
    if (this.$route.query && this.$route.query.msgCategory) {
      console.log('this.$route.query.msgCategory' + this.$route.query.msgCategory)
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeKind({ type, record }) {
      if (type == 1) {
        console.log(record, 111)
        this.$refs.signContractInfo.handleOpen(record)
      } else {
        this.handleUploadContract(record)
      }
    },
    handleChangeType({ record }) {
      this.$refs.UploadContract.handleOpen(record)

    },
    handleEmitContract(e) {
      this.$refs.signContractInfo.handleOpen(e)
    },
    handleViewInfo(item) {
      this.$refs.viewContract.handleOpen(item)
    },
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${ key }Range`] = []
      }
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleInit() {
      getAction('/quote/dictionary').then((res) => {
        const { success, data } = res
        success && (this.mainOptions = data)
      })
    },
    handleJump(url) {
      window.open(url)
    },

    handleUploadContract(item) {
      this.$refs.newUploader.handleOpen(item)
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    handleConfirmSplit(item) {
      this.$refs.splitOrderInfoRef.handleShowOrder({ ...item })
    },
    handleEmitSign(record) {
      this.$refs.ReNewSignRef.handleOpen(record)
    },
    /* 开启合同上传 */
    handleOPenContractUploader(item) {
      this.orderNo = item.orderNo
      // 开启抽屉
      this.$refs.UploadContract.handleOpen(item)
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      }
      const { startDate, endDate, keywords, invoiceStatus } = this.queryParams
      let statusList = [], params = { keywords, startDate, endDate }
      if (invoiceStatus) {
        params.invoiceStatusList = invoiceStatus.split(',')
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      console.log(this.CreateTimeScoped)
      if (this.CreateTimeScoped != '' && this.CreateTimeScoped != -1) {
        if (this.CreateTimeScoped == '6') {
          let lastYear = new Date().getFullYear() - 1
          params = { ...params, startDate: `${ lastYear }-01-01 00:00:00`, endDate: `${ lastYear }-12-31 23:59:59` }
        } else {
          const [startDate, endDate] = getDateRange(this.CreateTimeScoped)
          params = { ...params, startDate, endDate }
        }
      } else if (this.CreateTimeScoped != -1 && this.CreateTimeScopedRange.length) {
        const [startDate, endDate] = this.CreateTimeScopedRange
        params = { ...params, startDate, endDate }
      }

      params.applyDateStart = params.startDate
      params.applyDateEnd = params.endDate
      delete params.startDate
      delete params.endDate

      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        statusList,
        pagesSize: pageSize,
        pageNum,
        ...params
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.dataSource = list
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    }
    , handleReload() {
      this.queryParams = {
        status: [],
        orderNo: null
      }
      this.CreateTimeScopedRange = []
      this.invoiceStatus = ''
      this.CreateTimeScoped = '2'
      this.handleLoadData(1)
    },
    handleSwitchTab(record) {
      this.$refs.invoicedetail.handleOpen(record)
      // const { id } = record
      // console.log(this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1)
      // this.$router.push(
      //   `/invoice/detail?id=${ id }`
      // )


    }, handleToVoid(record) {
      this.targetVoidId = record.id
      this.toVoidvisible = true
    }, handleCancel(record) {
      this.toVoidvisible = false
      this.refundVisible = false
    }, handleRefund(record) {
      this.targetVoidId = record.id
      this.refundVisible = true
    },
    //发票作废
    handleVoidOk() {
      getAction('/v1/invoice/invoiceCancel', { id: this.targetVoidId }).then(res => {
        const { success, message } = res
        if (success) {
          this.toVoidvisible = false
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    //发票退票
    handleRefundOk() {
      this.$refs['basicForm'].validate((valid) => {
        console.log(valid)
        if (valid) {
          this.form.id = this.targetVoidId
          postAction('/v1/invoice/invoiceRefund', this.form)
            .then(res => {
            const { success, message } = res
            if (success) {
              this.$message.success("退票成功")
              this.refundVisible = false
              this.handleLoadData(1)
            } else {
              this.$message.warning(message)
            }
          })
            .catch((e) => {
            })
        }
      })
    },
    handleDownload(record) {
      if (!record.invoiceFile) return this.$message.error('暂无发票文件')
      record.invoiceFile.split(',').map((item) => {
        downloadImage(item)
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;

    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';

::v-deep .ant-form-item-label {
  width: 70px !important;
}

::v-deep .ant-form-item-label > label::after {
  margin-right: 0PX !important;
}

.fileUploader {
  display: none;
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.inline-name {
  width: 100%;
  display: flex;
  align-items: center;

  span.label {
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

span.tag {
  display: inline-block;
  width: 32px;
  height: 20px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: #05c29c;
  margin-right: 6px;

  &.danger {
    background: #ee4261;
  }
}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}
</style>
