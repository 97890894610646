<template>
  <div class="infoCard">
    <div class="card-info-box" style="padding: 0px 24px 24px 24px">
      <a-form-model
        class="form-cover"
        ref="basicForm"
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        :colon="false"
      >
        <a-row :gutter="26" class="basicInfo-form-item">
          <a-col :span="26" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="抬头类型"
              prop="headType" :rules="{ required: true, message: '请选择抬头类型' }"
            >
              <a-radio-group v-model="form.headType" style="padding-left: 16px">
                <a-radio :value="1">
                  个人
                </a-radio>
                <a-radio :value="2">
                  企业
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="发票抬头"
              prop="headDesc"
              :rules="{ required: true, message: '请选择抬头类型' }"
            >
              <a-input disabled v-model="form.headDesc" style="width: 320px; height: 38px;margin-left: 16px"
                       v-if="form.headType===2"></a-input>
              <a-input disabled value="个人" style="width: 320px; height: 38px;margin-left: 16px" v-else></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="默认发票类型"
              v-model="form.invoiceType"
              prop="invoiceType"
              :rules="{ required: true, message: '请选择抬头类型' }"
            >
              <a-radio-group style="padding-left: 16px" v-model="form.invoiceType">
                <a-radio :value="1">增值税普通发票
                </a-radio>
                <a-radio :value="2" v-if="form.headType===2">增值税专用发票
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="form.headType===2">
          <a-col :span="24" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="统一社会信用代码"
              prop="creditCode"
              :rules="{ required: true, message: '请输入' }"
            >
              <a-input v-model="form.creditCode"
                       style="width: 320px; height: 38px;margin-left: 16px"
                       placeholder="请填写15到20位有效纳税人识别号"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="开户银行名称"
              prop="invoiceBankName"
            >
              <a-input v-model="form.invoiceBankName"
                       style="width: 320px; height: 38px;margin-left: 16px"
                       placeholder="请填写您开户许可证上的开户银行名称"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="基本开户账户"

              prop="invoiceBankAccount"
            >
              <a-input
                v-model="form.invoiceBankAccount"
                style="width: 320px; height: 38px;margin-left: 16px"
                placeholder="请填写您开户许可证上的开户银行账户"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="注册场地地址"
              prop="registerAddress"
            >
              <a-input
                v-model="form.registerAddress"
                style="width: 320px; height: 38px;margin-left: 16px"
                placeholder="请填写您营业执照上的注册地址"
              ></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" style="padding: 0px 0px 20px 0px">
            <a-form-model-item
              label="注册固定电话"
              prop="registerPhone"
            >
              <a-input
                v-model="form.registerPhone"
                style="width: 320px; height: 38px;margin-left: 16px"
                placeholder="请填写您公司有效的联系电话"
              ></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24" style="padding: 0px 0px 20px 0px;text-align: center;width: 500px;">
            <a-button style="width: 200px"
                      :loading="loading" type="primary" @click="handleSubmit">提交
            </a-button>
          </a-col>
        </a-row>

      </a-form-model>

    </div>
  </div>
</template>

<script>
import ZPagination from '@/components/plugins/ZPagination.vue'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ListMixin from '@/mixins/listViewLoader'
import { mapGetters } from 'vuex'
import { postAction, getAction, uploadAction } from '@/api/manage'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { getCurBusiness, getDateNewRange } from '@/util/utils'
import uploadFile from '@/components/plugins/uploadFile.vue'
import AddressPicker from '@/views/businessManagement/companyInfo/modules/addressPicker.vue'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import ProvincePicker from '@/views/businessManagement/companyInfo/modules/provincePicker.vue'

export default {
  name: 'ContractManagement',
  mixins: [ListMixin],
  components: {
    ProvincePicker, JDictSelect, AddressPicker, uploadFile,
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup
  },

  data() {
    return {
      value: 1,
      showMore: false,
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      queryParams: {
        status: undefined
      },
      rangeDate: [],
      columns: [
        {
          title: '姓名 ',
          dataIndex: 'contractNo',
          key: 'contractNo',
          width: 218
        },
        {
          title: '电话',
          dataIndex: 'purchaseBusinessName',
          key: 'purchaseBusinessName',
          width: 180,
          scopedSlots: { customRender: 'purchaseBusinessName' }
        },

        {
          title: '所在区域',
          dataIndex: 'supplierBusinessName',
          key: 'supplierBusinessName',
          width: 180,
          scopedSlots: { customRender: 'supplierBusinessName' }
        },
        {
          title: '详细地址',
          dataIndex: 'rangeTime',
          align: 'center',
          key: 'rangeTime',
          width: 240,
          scopedSlots: { customRender: 'rangeTime' }
        },
        {
          title: '默认地址',
          dataIndex: 'status',
          key: 'status',
          width: 140,
          align: 'center',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 180
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 200,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      quoteOptions: [
        {
          dictKey: '1',
          dictValue: '待生效'
        },
        {
          dictKey: '2',
          dictValue: '进行中'
        },
        {
          dictKey: '3',
          dictValue: '已过期'
        }
      ],
      otherOptions: [
        {
          dictKey: '0',
          dictValue: '本月'
        },
        {
          dictKey: '3',
          dictValue: '三个月'
        },
        {
          dictKey: '4',
          dictValue: '近半年'
        }
      ],
      mainOptions: [],
      activeInfo: {},
      requestType: 'post',
      url: {
        list: '/v1/contract/v1/contract/pageList'
      },
      statusDictList: {
        2: '进行中',
        1: '待生效',
        3: '已过期',
        4: '已终止'
      },
      statusDictStyle: {
        2: 'status_4',
        1: 'status_2',
        3: 'status_10',
        4: 'status_10'
      },
      statusADictList: {
        0: '待生效',
        1: '待签署',
        2: '已签署 '
      },
      //  0:发起 1:签署中 2 签署完成
      statusADictStyle: {
        0: 'status_1',
        1: 'status_6',
        2: 'status_4'
      },
      createTimeScoped: '',
      createTimeRange: [],
      orderNo: null,
      disableMixinCreated: true,
      isPersonal: false,
      form: {}
    }
  },
  created() {
    this.dataSource = [{ name: '11', phone: '18612983759' }, {}, {}]
    this.handleInit()
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleChangeRadio(e, key) {
      if (e != '-1') {
        this[`${key}Range`] = []
      }
    },
    handleChangeDate(e, key) {
      if (e && e.length) {
        this[key] = -1
      }
    },
    handleInit() {
      const payload = {}
      payload.businessId = this.setUserInfo().businessId
      getAction('/business/invoiceHead/queryByBusinessId', payload).then((res) => {
        const { success, data } = res
        if (success) {
          const business =   getCurBusiness();
          if (data == null) {
            this.form = {}
            this.form.headDesc = business?.name
          } else {
            this.form = data
            this.form.headDesc = business?.name
          }
        }
      })
    },
    handleJump(url) {
      window.open(url)
    },
    handleSearch() {
      if (this.rangeDate.length) {
        const [submitStartDate, submitEndDate] = this.rangeDate
        this.queryParams['submitStartDate'] = submitStartDate
        this.queryParams['submitEndDate'] = submitEndDate
      }
      this.handleLoadData(1)
    },
    handleLoadData(flag) {
      if (flag) {
        this.pageNation = {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      }
      const { quoteSource, status, contractNo, startDate, endDate, cooperationStatus } = this.queryParams
      let statusList = [], params = { contractNo, startDate, endDate }
      if (quoteSource) {
        // quoteSourceList = quoteSource.split(',')
      }
      if (status) {
        statusList = status.split(',')
      }
      if (cooperationStatus) {
        params = { ...params, cooperationStatus: cooperationStatus.split(',') }
      }
      // 创建时间
      if (this.createTimeScoped != '' && this.createTimeScoped != -1) {
        const [createStartDate, createEndDate] = getDateNewRange(this.createTimeScoped)
        params = { ...params, createStartDate, createEndDate }
      } else if (this.createTimeScoped != -1 && this.createTimeRange.length) {
        const [createStartDate, createEndDate] = this.createTimeRange
        params = { ...params, createStartDate, createEndDate }
      }
      if (!this.url && !this.url.list) return this.$message.warning('请设置请求地址')
      let pageParams = { ...this.pageNation }
      delete pageParams.total
      this.loading = true
      this.dataSource = []
      const { pageSize, pageNum } = pageParams
      let api = this.requestType == 'get' ? getAction : this.requestType == 'post' ? postAction : postQueryAction
      api(this.url.list, {
        statusList,
        pagesSize: pageSize,
        pageNum,
        ...params
      })
        .then((res) => {
          const { success, data } = res
          this.loading = false
          if (success) {
            const { total, list } = data
            this.dataSource = list
            this.pageNation['total'] = total
          }
        })
        .catch((e) => {
          this.loading = false
        })
    },
    reaLoad() {
      this.pageNation = {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
      this.queryParams = {}
      this.createTimeRange = []
      this.dataSource = []
      this.handleLoadData(1)
    },
    handleSubmit() {
      this.$refs['basicForm'].validate((valid) => {
        if (valid) {
          let params = {
            ...this.form
          }
          params.businessId = this.setUserInfo().businessId
          // 供应商
          this.loading = true
          let url = this.form?.id ? '/business/invoiceHead/update':'/business/invoiceHead/add'
          postAction(url, {
            ...params
          })
            .then((res) => {
              this.loading = false
              const { success, message } = res
              if (success) {
                this.$message.success('保存成功')
                this.handleInit()
              } else {
                this.$message.error(message)
              }
            })
            .catch((e) => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-table {
  color: rgba(0, 0, 0, 0.85);
}

// ::v-deep .ant-table-column-title {
//   font-size: 16px;
// }

::v-deep .ant-table-thead {
  tr {
    background: #f4f5f7 !important;

    th {
      border-bottom: none;
      font-size: 14px;
      font-weight: 600 !important;
      color: #131212;
    }
  }
}

::v-deep .ant-table-tbody {
  tr {
    // &:not(:last-of-type)>td {
    //   border: none;
    // }

    & > td {
      border-bottom: 1px solid #efefef;
    }
  }
}

::v-deep .ant-form-item-control {
  line-height: 30px;
}

::v-deep .ant-form-item-label {
  line-height: 30px;
}

::v-deep .ant-table-thead > tr > th {
  background: #efefef;
  border-bottom: none;
  font-weight: 500;
}

.searchInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  .option-area {
    margin-left: 8px;

    .ant-btn {
      width: 97px;
      height: 38px;

      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}

@import '~@/styles/list.less';


.fileUploader {
  display: none;
}

.pickerDict {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  min-width: 140px;
  height: 26px;
  color: #131212;
  cursor: pointer;
  padding: 0 6px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;

  .placeholder {
    color: #999;
  }
}

.row_title {
  display: flex;
  align-items: center;
}

img.newIcon {
  width: 16px;
  margin-left: 6px;
}

.inline-name {
  width: 100%;
  display: flex;
  align-items: center;

  span.label {
    display: inline-block;
    width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

span.tag {
  display: inline-block;
  width: 32px;
  height: 20px;
  border-radius: 3px;
  font-size: 12px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  background: #05c29c;
  margin-right: 6px;

  &.danger {
    background: #ee4261;
  }
}

::v-deep .ant-form-item-label {
  width: 145px !important;
  display: flex;
  text-align: right !important;
  justify-content: flex-end;
}

::v-deep .ant-form-item {
  display: flex;
  flex-direction: row;
}
</style>
