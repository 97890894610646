<!--
供应商发票详情界面
-->
<template>
  <DrawerView :visible="visible" :width="1200" title="发票详情" @cancel="handleDeawerCancel" show-slot-title="true">
    <div class="invoiceDetails">
      <div class="card-info-box" style="padding:37px 36px 24px 36px" v-if="stepsList.length>0">
        <a-steps
          progress-dot
          size="small"
          :current="stepCurrent"
        >
          <a-step :title="i.title" :description="i.date" v-for="i in stepsList" :key="i.title" />
        </a-steps>
      </div>

      <div class="invoice-content">
        <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
          <h2 class="RequirementConfirmation-title">
            <span class="title">发票信息</span>
            <a class="action">
              <!--            <img src="~@/assets/export_icon.png" alt="" />-->
            </a>
          </h2>
        </div>

        <a-descriptions :column="2" bordered style="margin-bottom: 25px;" v-if="invoiceData.orderInvoiceDTO">
          <a-descriptions-item label="购买方名称">
            {{ invoiceData.orderInvoiceDTO.purchaseBusinessName }}
          </a-descriptions-item>
          <a-descriptions-item label="销售方名称">
            {{ invoiceData.orderInvoiceDTO.supplierBusinessName }}
          </a-descriptions-item>
          <a-descriptions-item label="发票金额"
          >
            <span style="color:  #FF6026;"> ¥ {{ invoiceData.orderInvoiceDTO.amount }}</span>
          </a-descriptions-item>
          <a-descriptions-item label="发票类型">
            {{ invoiceData.orderInvoiceDTO.invoiceType == 1 ? '增值税普通发票' : '增值税专用发票' }}
          </a-descriptions-item>
          <a-descriptions-item label="备注" :span="2">
            {{ invoiceData.orderInvoiceDTO.remark }}
          </a-descriptions-item>
        </a-descriptions>

        <div class="RequirementConfirmation-content-item" style="margin-bottom: 15px">
          <h2 class="RequirementConfirmation-title">
            <span class="title">发票关联订单</span>
            <a class="action">
              <!--            <img src="~@/assets/export_icon.png" alt="" />-->
            </a>
          </h2>
        </div>

        <a-table
          :columns="columns"
          :loading="loading"
          :data-source="invoiceData.orderInvoiceDTO.orderInvoiceRelations"
          :pagination="false"
          style="margin-bottom: 16px"
          :scroll="{ x: 'max-content' }"
          rowKey="id"
        >
          <template slot="name" slot-scope="text">
            <a>{{ text }}</a>
          </template>

        </a-table>
        <!--        <ZPagination-->
        <!--          :total="pageNation.total"-->
        <!--          :current="pageNation.pageNum"-->
        <!--          :pageSize="pageNation.pageSize"-->
        <!--          @handlePageChange="handleChangePage"-->
        <!--        />-->
      </div>
    </div>

    <a-modal title="发票作废确认" :visible="toVoidvisible" centered @ok="handleVoidOk" @cancel="handleCancel">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>请确认是否需要作废发票？作废发票后，可重新申请发票~</span>
      </div>
    </a-modal>
    <a-modal title="发票退票确认" :visible="refundVisible" centered @ok="handleRefundOk" @cancel="handleCancel">
      <div class="info-card">
        <a-form-model
          class="form-cover"
          ref="basicForm"
          v-model="basicForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :colon="false"
        >
          <a-row :gutter="26" class="basicInfo-form-item">
            <a-col :span="24">
              <a-form-model-item style="display: flex;"
                                 label="退票原因"
                                 :rules="{ required: true, message: '退票原因' }"
              >
                <j-dictSelect
                  style="width: 320px; height: 38px;margin-left: 16px"
                  v-model="basicForm.reason"
                  dictCode="1540"
                  placeholder="请选择"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="26" class="basicInfo-form-item">
            <a-col>
              <a-form-model-item style="display: flex;"
                                 label="备注"
                                 :rules="{ required: true, message: '备注' }"
              >
                <a-textarea style="width: 320px;min-height: 100px; margin-left: 16px" placeholder="请输入"
                            v-model="basicForm.refundRemark"
                            allow-clear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
    <template v-slot:btnArea>
      <a-button style="padding: 0px 27px;margin-right: 8px " @click="onCancel">取消</a-button>
      <a-button type="primary" @click="handleDownload(invoiceData.orderInvoiceDTO)">下载发票</a-button>
    </template>
  </DrawerView>
</template>

<script>

import ZPagination from '@/components/plugins/ZPagination.vue'
import ListMixin from '@/mixins/listViewLoader'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import checkboxSearch from '@/components/plugins/checkboxSearch.vue'
import radioGroup from '@/components/plugins/radioGroup.vue'
import { getAction, postAction } from '@/api/manage'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import DrawerView from '@/components/plugins/drawerView.vue'
import { downloadImage } from '@/util/utils'

export default {
  name: 'ContractManagement',
  mixins: [ListMixin],
  components: {
    DrawerView,
    JDictSelect,
    ZPagination,
    EmptyArea,
    checkboxSearch,
    radioGroup
  },

  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      visible: false,
      toVoidvisible: false,
      refundVisible: false,
      stepsList: [],
      stepCurrent: 0,
      dataSourceModel: [{
        orderNo: '2312312',
        orderName: '12312312',
        orderType: '预付全款',
        compayName: '上海我是供应有限公司',
        payable: '19890.00'
      }],
      columns: [
        {
          title: '订单编号 ',
          dataIndex: 'orderNo',
          key: 'orderNo',
          width: 218
        },
        {
          title: '订单名称 ',
          dataIndex: 'orderTitle',
          key: 'orderTitle',
          width: 180,
          scopedSlots: { customRender: 'orderTitle' }
        },

        {
          title: '开票款项',
          dataIndex: 'paymentMethodDictName',
          key: 'paymentMethodDictName',
          width: 180,
          scopedSlots: { customRender: 'paymentMethodDictName' }
        },
        {
          title: '开票企业',
          dataIndex: 'supplierBusinessName',
          // align: 'center',
          key: 'supplierBusinessName',
          width: 240,
          scopedSlots: { customRender: 'supplierBusinessName' }
        },
        {
          title: '开票金额 ',
          dataIndex: 'invoiceAmount',
          key: 'invoiceAmount',
          width: 140,
          scopedSlots: { customRender: 'invoiceAmount' }
        }],
      targetVoidId: null,
      invoiceData: {},
      basicForm: {}
    }
  },
  created() {
  },
  methods: {
    handleOpen(item) {
      console.log(item)
      this.visible = true
      this.targetVoidId = item.id
      this.handleLoadData()
    },
    handleLoadData() {
      getAction('/v1/invoice/invoiceDetail', {
        id: this.targetVoidId
      }).then((res) => {
        const { success, data, message } = res
        if (success) {
          this.invoiceData = data
          const stepsList = []
          stepsList.push({ title: '申请时间', date: data.orderInvoiceDTO.applyTime })
          if (data.orderInvoiceDTO.invoiceTime) {
            stepsList.push({
              title: '已开票',
              date: data.orderInvoiceDTO.invoiceTime
            })
            this.stepCurrent = 1
          } else {
            stepsList.push({
              title: '待开票',
              date: '-'
            })
            this.stepCurrent = 0
          }
          if (data.orderInvoiceDTO.redRushTime) {
            stepsList.push({
              title: '已红冲',
              date: data.orderInvoiceDTO.redRushTime
            })
            this.stepCurrent = 2
          }
          this.stepsList = stepsList
        } else {
          this.$message.warning(message)
        }
      })
    },
    onCancel() {
      this.visible = false

    },

    handleToVoid() {
      this.toVoidvisible = true
    },
    handleRefund() {
      this.refundVisible = true
    },
    //发票作废
    handleVoidOk() {
      getAction('/v1/invoice/invoiceCancel', { id: this.targetVoidId }).then(res => {
        const { success, message } = res
        if (success) {
          this.toVoidvisible = false
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleRefundOk() {
      this.basicForm.id = this.targetVoidId
      postAction('/v1/invoice/invoiceRefund', this.basicForm).then(res => {
        const { success, message } = res
        if (success) {
          this.refundVisible = false
          this.handleLoadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    }, handleCancel() {
      this.refundVisible = false
      this.toVoidvisible = false
    }, handleDeawerCancel() {
      this.visible = false
    }, handleDownload(record) {
      if (!record.invoiceFile) return this.$message.error('暂无发票文件')
      record.invoiceFile.split(',').map((item) => {
        downloadImage(item)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.invoiceDetails {
  box-sizing: border-box;
  background-color: #ffffff;
}

.RequirementConfirmation {
  .header {
    &-title {
      color: #090b12;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    &-subtitle {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      margin-bottom: 15px;

      .copy {
        font-size: 13px;
        margin-left: 8px;
        font-weight: 500;
      }
    }
  }

  &-title {
    font-size: 15px;
    color: #605f5f;
    margin-bottom: 24px;
    height: 38px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:before {
      background: #ff6e2d;
      display: inline-block;
      content: '';
      width: 5px;
      height: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000;
      margin-right: 10px;
    }

    .subTitle {
      font-weight: 400;
      font-size: 14px;
      color: #8d8e99;
    }
  }

  &-content {
    &-item {
      width: 100%;
      background: #fff;
      position: relative;

      .edit-btn {
        position: absolute;
        top: 4px;
        right: -15px;

        img {
          width: 15px;
          margin-right: 5px;
        }
      }
    }
  }

  ::v-deep .ant-form-item-label {
    line-height: 22px;

    label {
      color: #626161 !important;
    }
  }

  ::v-deep .ant-form-item-control {
    line-height: 22px;
    color: #131212;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .subTitle {
    width: calc(100% - 40px);
    font-size: 15px;
    color: #ff6e2d;
    padding-bottom: 8px;
    border-bottom: 1px solid #efefef;
    margin: 0 auto 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tag-title {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      background: #fff2ea;
      border-radius: 2px;
    }

    img {
      width: 15px;
      margin-right: 4px;
    }

    span {
      line-height: 22px;
      font-size: 14px;
    }
  }

  .basicInfo-form {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 18px 0 4px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    &-item {
      padding: 0 20px;
    }
  }

  ::v-deep .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-btn-link[disabled] img {
    filter: grayscale(100%);
  }

  .price-title {
    width: 107px;
    margin-top: 8px;
  }

  .process-content {
    background: #f7f8fa;
    border-radius: 8px;
    padding: 24px;

    ::v-deep .ant-timeline-item-tail {
      border: 0.5px dashed #dcdcdc;
    }

    ::v-deep .ant-timeline-item-head {
      width: 5px;
      height: 5px;
      background: #d7d7db;
      border: none;
      left: 2px;
      top: 3px;
    }

    .content {
      padding-top: 5px;
      color: #000000;
      font-size: 14px;
      line-height: 20px;
    }

    .time {
      font-size: 14px;
      line-height: 20px;
      color: #8d8e99;
    }
  }
}

.ant-descriptions {
  // background-color: #f7f8fa;
  // padding: 16px 25px 0;
  // border-radius: 8px;

  &.tag_2 {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep .ant-descriptions-item-label {
  color: #595959;
}

::v-deep .ant-descriptions-item-content {
  color: #131212;
}

::v-deep .ant-radio-disabled + span {
  color: #262626;
}

.card-view {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 25px 25px;

  &-item {
    width: calc((100% - 50px) / 3);
    height: 144px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px dashed #e8e9eb;
    padding: 15px;
    display: flex;
    position: relative;

    .cover {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      object-fit: cover;
      margin-right: 15px;
      cursor: pointer;

      &:hover {
        & + .option-area {
          opacity: 1;
          z-index: 10;
        }
      }
    }

    .option-area {
      width: 112px;
      height: 112px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.65);
      position: absolute;
      left: 15px;
      top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
      z-index: -1;

      &:hover {
        opacity: 1;
        z-index: 10;
      }

      img {
        height: 16px;
        cursor: pointer;
      }

      .split-line {
        width: 1px;
        height: 14px;
        background-color: #fff;
        margin: 0 15px;
      }
    }
  }

  //.info-card {
  //  &.inside {
  //    flex: 1 0 0;
  //    min-width: 0;
  //  }
  //
  //  .sub_title {
  //    font-size: 14px;
  //    font-weight: 400;
  //    color: #131212;
  //    line-height: 22px;
  //    margin-bottom: 8px;
  //    overflow: hidden;
  //    .text-ellipsis-multi(2);
  //  }
  //
  //  div.labels {
  //    font-size: 12px;
  //    color: #8d8e99;
  //    line-height: 20px;
  //    font-weight: 400;
  //  }
  //}
}

.desc-title {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #efefef;
  padding-bottom: 12px;
  margin-bottom: 15px;

  span.title {
    font-size: 14px;
    color: #131212;
  }
}

div.file-card {
  width: max-content;
  height: 64px;
  background: #fff;
  border-radius: 2px;
  padding: 8px 12px 8px 12px;
  margin-bottom: 8px;

  &-title {
    margin-bottom: 15px;

    img.icon {
      width: 16px;
      height: 16px;
      margin-right: 6px;
    }

    .fileName {
      color: #1d2129;
      font-weight: 400;
      font-size: 14px;
    }
  }

  &-size {
    font-weight: 400;
    color: #8d8e99;
    height: 22px;
    line-height: 22px;
    font-size: 12px;
    padding-left: 22px;
  }
}

.coverBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.last-label {
  width: 100%;
  height: 102px;
  background-image: url('~@/assets/longerBG.png');
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  .ant-form-item {
    margin-bottom: 0;
  }

  ::v-deep .ant-input {
    border-color: #ff9a57;
  }

  ::v-deep .ant-input-suffix {
    color: #131212;
  }

  .moneyIcon {
    position: absolute;
    bottom: 0;
    right: 19px;
  }
}

.numberInput {
  width: 320px;
  border-color: #ff7d2f;
  height: 38px;
  line-height: 38px;

  ::v-deep .ant-input-number-input {
    font-size: 18px;
    color: #131212;
    font-weight: 500;
  }
}

.btns {
  text-align: center;
}

.cover-box {
  margin-top: 15px;
  text-align: right;
}

.info-area-botton {
  position: relative;
  bottom: 0px;
  width: 100%;
  box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.08);
  display: flex;
  box-sizing: border-box;
  padding: 16px 8px 16px 24px;
  justify-content: flex-end;
}

@import '~@/styles/form.less';
@import '~@/styles/orderDetail.less';
.action {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 14px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;

  img {
    width: 20px;
    margin-right: 4px;
  }
}

.card-info-box {

}

.info-card {
  font-size: 16px;
  color: #131212;
  display: flex;
  align-items: center;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}

::v-deep .ant-form-item-label {
  width: 70px !important;
}

::v-deep .ant-form-item-label > label::after {
  margin-right: 0PX !important;
}
</style>
